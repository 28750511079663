// Global Functions
const GRIT = {};

/**
 * Vendors
 */
import 'picturefill';
import 'lazysizes';
import './modules/ls.object-fit';
import './modules/js.cookie';
import './modules/ga-events';
import './modules/validate';
import './modules/panzoom';

GRIT.polyfills = () => {

    /**
     * Polyfill closest
     */
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function (selector) {
            let el = this;

            do {
                if (el.matches(selector)) {
                    return el
                }

                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);

            return null;
        };
    }

    /**
     * Array.prototype.forEach() polyfill
     * @author Chris Ferdinandi
     * @license MIT
     */
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
            thisArg = thisArg || window;
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this);
            }
        };
    }
}


GRIT.global = () => {
    /**
     * Global settings
     */
    // Preload and No-JS
    setTimeout(function () {
        document.documentElement.classList.remove('preload');
        document.documentElement.classList.remove('no-js');
    }, 300);
}


GRIT.accessibility = () => {
    /**
     * Toggle focus on when users are tabbing, and turn them off once
     * they begin clicking again
     */
    const handleFirstTab = (e) => {
        if (e.keyCode === 9) {
            document.body.classList.add('has-tabbing-user');

            window.removeEventListener('keydown', handleFirstTab);
            window.addEventListener('mousedown', handleMouseDownOnce);
        }
    };

    const handleMouseDownOnce = () => {
        document.body.classList.remove('has-tabbing-user');

        window.removeEventListener('mousedown', handleMouseDownOnce);
        window.addEventListener('keydown', handleFirstTab);
    };

    window.addEventListener('keydown', handleFirstTab);
}

GRIT.navigation = () => {
    /**
     * Header and navigation hooks
     */
    const nav = document.querySelector('#header-nav');
    const extra = document.querySelector('.c-header__extra');
    const navToggle = document.querySelector('#nav-toggle');
    const root = document.documentElement;
    const body = document.body;
    const breakpoint = 1001;

    if (window.innerWidth < breakpoint) {
        nav.setAttribute('aria-hidden', true);
        extra.setAttribute('aria-hidden', true);
    }

    navToggle.addEventListener('click', () => {
        nav.classList.toggle('is-active');
        extra.classList.toggle('is-active');
        navToggle.classList.toggle('is-active');
        document.querySelector('body').classList.toggle('has-active-nav');

        if (!navToggle.classList.contains('is-active')) {
            nav.setAttribute('aria-hidden', true);
            extra.setAttribute('aria-hidden', true);

            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
            root.classList.add('smooth-scroll');
        } else {
            nav.setAttribute('aria-hidden', false);
            extra.setAttribute('aria-hidden', false);

            const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
            body.style.position = 'fixed';
            body.style.top = `-${scrollY}`;
            root.classList.remove('smooth-scroll');
        }
    });

    window.addEventListener('scroll', () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });

    window.addEventListener('resize', () => {
        if (window.innerWidth >= breakpoint && navToggle.classList.contains('is-active')) {
            const click = new Event('click');
            navToggle.dispatchEvent(click);
        }
    });
}

GRIT.accordion = () => {

    const accordions = document.querySelectorAll('.c-accordion');

    accordions.forEach((accordion) => {

        function open(button, panel) {
            button.setAttribute('aria-expanded', true);
            button.classList.add('is-open');
            panel.classList.add('is-visible');
            panel.style.height = 'auto';
            let panelHeight = panel.offsetHeight;
            panel.style.height = `${panelHeight}px`;
        }

        function close(button, panel) {
            button.setAttribute('aria-expanded', false);
            button.classList.remove('is-open');
            panel.classList.remove('is-visible');
            panel.removeAttribute('style');
        }

        const panels = accordion.querySelectorAll('.c-accordion__panel');
        const buttons = accordion.querySelectorAll('.c-accordion__button');

        let firstPanel = panels[0];
        let firstButton = buttons[0];

        open(firstButton, firstPanel);

        document.addEventListener('click', function (event) {

            if (!event.target.matches('.c-accordion button')) {
                return;
            }

            let item = event.target.getAttribute('data-toggle');
            let panel = accordion.querySelector('[data-content=' + item + ']');

            panels.forEach((element) => {
                if (element.classList.contains('is-visible') && element !== panel) {
                    element.classList.remove('is-visible');
                    element.removeAttribute('style');
                }
            });

            buttons.forEach((element) => {
                if (element !== event.target) {
                    element.classList.remove('is-open');
                    element.setAttribute('aria-expanded', false);
                }
            });

            if (panel.classList.contains('is-visible')) {
                close(event.target, panel);
            } else {
                open(event.target, panel);
            }

        });

    });

};


GRIT.ajax = () => {
    /**
     * Ajax filters
     */
    const ajaxFilters = document.querySelectorAll('[data-ajax="filter"]');
    const ajaxIUpdateArea = document.querySelector('[data-ajax="update"]');
    const header = document.querySelector('.c-post-list__header');

    const serialize = (form) => {

        let serialized = [];

        for (let i = 0; i < form.elements.length; i++) {

            let field = form.elements[i];
            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

            if (field.type === 'select-multiple') {
                for (var n = 0; n < field.options.length; n++) {
                    if (!field.options[n].selected) continue;
                    serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
                }
            } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
            }
        }

        return serialized.join('&');

    };


    const ajaxFetch = (url) => {

        const xmlhttp = new XMLHttpRequest();

        ajaxIUpdateArea.classList.add('is-loading');

        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
                if (xmlhttp.status === 200) {

                    const parser = new DOMParser();
                    const htmlDocument = parser.parseFromString(xmlhttp.response, "text/html");

                    setTimeout(function () {
                        ajaxIUpdateArea.innerHTML = htmlDocument.documentElement.querySelector('[data-ajax="update"]').innerHTML;
                    }, 600);

                    setTimeout(function () {
                        ajaxIUpdateArea.classList.remove('is-loading');
                    }, 600);

                    window.history.pushState('', '', url);

                } else if (xmlhttp.status === 400) {
                    console.warn('There was an error 400');
                } else {
                    console.warn(xmlhttp.status);
                }
            }
        };

        xmlhttp.open('GET', url, true);

        xmlhttp.send();

    };

    document.addEventListener('click', function (e) {

        if (e.target.matches('.c-pagination a')) {
            e.preventDefault();
            ajaxFetch(e.target.getAttribute('href'));
            header.scrollIntoView();
        }

    });

    ajaxFilters.forEach(function (filter) {

        let buttonRefresh = filter.querySelector('[data-ajax="filter"] button');
        buttonRefresh.parentNode.removeChild(buttonRefresh);

        filter.addEventListener('change', function (e) {

            let params = serialize(filter);

            if (params.length > 0) {
                params = filter.getAttribute('action').indexOf('?') > -1 ? '&' + params : '?' + params;
            }

            ajaxFetch(filter.getAttribute('action') + params);

        });
    });
}

GRIT.alert = () => {

    /**
     * Alert
     */
    const alert = document.querySelector('.c-alert');
    const alertClose = document.querySelector('.c-alert__close');

    if (!alert) {
        return;
    }

    alertClose.addEventListener('click', function (event) {
        alert.classList.add('fade-out');
        setTimeout(() => {
            sessionStorage.setItem('hideAlert', 'true');
            alert.classList.remove('is-visible');
        }, 600);
        console.log(hideAlert);
    });

    let hideAlert = sessionStorage.getItem('hideAlert');

    if (hideAlert !== 'true') {
        alert.classList.add('is-visible');
    }

};

GRIT.cookieNotice = () => {

    /**
     * Cookies notice
     */
    const cookiesNotice = document.querySelector('.c-cookie-notice');
    const cookiesDismiss = document.querySelectorAll('[data-action="dismiss"]');

    for (let i = 0; i < cookiesDismiss.length; i++) {
        cookiesDismiss[i].addEventListener('click', function (event) {
            Cookies.set('cookieNotice', 'dismissed', {expires: 30});
            cookiesNotice.classList.remove('is-visible');
        });
    }

    if (Cookies.get('cookieNotice') !== 'dismissed') {
        cookiesNotice.classList.add('is-visible');
    }
};

GRIT.infoTabs = () => {
    const controls = document.querySelectorAll('[data-controls="tab"]');
    const panels = document.querySelectorAll('[role="tabpanel"]');
    const tabs = document.querySelectorAll('[role="tab"]');

    if (!controls) return;

    let tabSelect = function (event) {

        if (!event.target.matches('[role="tab"]')) return;

        // Deselect
        for (let i = 0; i < tabs.length; i++) {

            tabs[i].setAttribute('aria-selected', 'false');
            tabs[i].classList.remove('is-active');
        }
        for (let i = 0; i < panels.length; i++) {

            panels[i].setAttribute('aria-expanded', 'false');
            panels[i].classList.remove('is-visible');
        }

        const panel = event.target.getAttribute('aria-controls');

        // Select
        event.target.classList.add('is-active');
        event.target.setAttribute('aria-selected', 'true');
        document.querySelector('#' + panel).classList.add('is-visible');
        document.querySelector('#' + panel).setAttribute('aria-expanded', 'true');

    };

    document.addEventListener('keydown', tabSelect, false);
    document.addEventListener('click', tabSelect, false);

};

GRIT.copyText = () => {
    /* Get the text field */
    let copyText = document.querySelector('[data-copy="content"]');

    document.addEventListener('click', function (e) {

        if (e.target.matches('[data-copy="button"]')) {
            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /*For mobile devices*/

            /* Copy the text inside the text field */
            document.execCommand('copy');
            e.target.classList.add('is-copied');
            e.target.innerHTML = 'Code Copied!';
        }

    });

}

GRIT.floorPlans = () => {

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    let query_store = getParameterByName('store-plan');

    window.onload=function(){
        if (query_store) {
            document.querySelector('[data-select-store="#fp-' + query_store + '"]').classList.add('is-active');
            document.querySelector('[data-select-store="#fp-' + query_store + '"]').click();
        }
    };

    // https://github.com/anvaka/panzoom
    document.querySelectorAll('.c-floor-plans__plan').forEach(plan => {

        panzoom(plan, {
            bounds: true,
            boundsPadding: 0.25
        });

    });

    document.addEventListener('click', function (e) {

        if (e.target.matches('.c-floor-plans__stores button') || e.target.matches('.c-floor-plans__buttons button')) {

            document.querySelector('.c-floor-plans__tabs').classList.add('is-visible');
            let planRef = e.target.getAttribute('data-select-tab');
            let storeRef = e.target.getAttribute('data-select-store');

            let storeButtons = document.querySelectorAll('.c-floor-plans__stores button');

            storeButtons.forEach(function (button) {
                button.classList.remove('is-active');
            });

            e.target.classList.add('is-active');

            window.history.pushState('', '', '?store-plan=' + storeRef.replace('#fp-', ''));
            document.getElementById('floor-plans').scrollIntoView();

            let button = document.querySelector('[aria-controls="' + planRef + '"]');
            button.click();

            let highlighted = document.querySelectorAll('.is-highlighted');

            highlighted.forEach(function (highlight) {
                highlight.classList.remove('is-highlighted');
            });

            if (storeRef.length > 0) {
                let store = document.querySelectorAll(storeRef);
                for(let i = 0; 0 < store.length; i++) {
                    store[i].classList.add('is-highlighted');
                }
            }

        }

        if (e.target.matches('[data-tabs="open"]')) {
            document.querySelector('.c-floor-plans__tabs').classList.add('is-visible');
        }

        if (e.target.matches('[data-tabs="close"]')) {
            document.querySelector('.c-floor-plans__tabs').classList.remove('is-visible');
        }

    });

}

GRIT.validation = () => {
    /**
     * Validation
     */
    document.querySelectorAll('form.js-validate').forEach(form => {
        new Validate(form, {
            'ajax': false,
            'recaptchaSiteKey': document.querySelector("meta[name='recaptcha_site_key']").getAttribute('content'),
            'fieldFeedbackSelector': '.js-field-feedback span',
            'alertList': '#flash-list',
        });
    });
}

GRIT.gtag = () => {

    window.dataLayer = window.dataLayer || [];

    function gtag() {
        dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'G-K84DZYP201');

};

GRIT.searchToggle = () => {

    const search = document.querySelector('#header-search');

    document.addEventListener('click', function (event) {

        if (!event.target.matches('[data-toggle="header-search"]')) return;

        if (search.getAttribute('aria-hidden') === 'true') {
            event.target.classList.add('is-active');
            search.setAttribute('aria-hidden', 'false');
            search.querySelector('input').focus();
        } else {
            event.target.classList.remove('is-active');
            search.setAttribute('aria-hidden', 'true');
        }

    });

}

GRIT.init = () => {
    // Call these functions on load
    GRIT.polyfills();
    GRIT.global();
    GRIT.accessibility();
    GRIT.navigation();
    GRIT.accordion();
    GRIT.ajax();
    GRIT.alert();
    GRIT.copyText();
    GRIT.infoTabs();
    GRIT.floorPlans();
    GRIT.validation();
    GRIT.gtag();
    GRIT.searchToggle();
};

GRIT.init();
